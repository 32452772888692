
<template>
  <div class="container-footer body-2" style="font-size: 12px; color: #cccccc">
    <div>
      <a class="invisible-link" href="https://klaassen.dev">development :: KLAASSEN.dev</a>
    </div>
    <div>
      <img :src="X" class="themed-svg-icon icon-grey" />
    </div>
    <div>
      <a class="invisible-link" href="https://iinf.in">design :: U29DC</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      X: require("../assets/X.svg"),
    };
  },
};
</script>

<style scoped>
.container-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.icon-grey {
  padding-top: 4px;
  height: 18px;
  filter: invert(85%) sepia(0%) saturate(0%) hue-rotate(192deg) brightness(97%) contrast(94%);
}
</style>
