import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import VueGtag from 'vue-gtag'

const AboutPage = () => import('./pages/AboutPage.vue')
// const CommunityPage = () => import('./pages/CommunityPage.vue')
const HomePage = () => import('./pages/HomePage.vue')
const SupportPage = () => import('./pages/SupportPage.vue')
const PrivacyPolicyPage = () => import('./pages/PrivacyPolicyPage.vue')
const TemplatePage = () => import('./pages/TemplatePage.vue')
const NotFoundPage = () => import('./pages/NotFoundPage.vue')

const routes = [
    {
        path: '/',
        component: HomePage,
        meta: {
            title: "Cutting Templates"
        }
    },
    {
        path: '/about',
        component: AboutPage,
        meta: {
            title: "About Cutting Templates"
        }
    },
    {
        path: '/support',
        component: SupportPage,
        meta: {
            title: "Supporting Cutting Templates"
        }
    },
    {
        path: '/privacy-policy',
        component: PrivacyPolicyPage,
        meta: {
            title: "Privacy Policy"
        }
    },
    {
        name: 'templates',
        path: '/templates/:template',
        component: TemplatePage,
    },
    {
        path: '/404',
        component: NotFoundPage,
        meta: {
            title: "Cutting Templates 404"
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
})

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = `${to.meta.title}`;
    }
    else {
        let p = window.location.pathname.split("/");
        p = p[p.length - 1];
        p = p.replaceAll("-", " ");
        p = p + " template";
        document.title = p;
    }

    next();
})

const app = createApp(App)

app.use(router)
app.use(VueGtag, {
    config: {
        id: "G-0VZXR55PNE"
    },
}, router)

app.mount('#app')
