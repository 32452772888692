<template>
  <div class="button-container">
    <a class="menu-button" href="/">TEMPLATES</a>
    <a class="menu-button" href="/about">ABOUT</a>
    <a class="menu-button action-button" href="/support">SUPPORT</a>
  </div>
</template>

<script>
export default {
  name: "Header",
  components: {},
};
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  padding: 1em 0;
}

@media (max-width: 820px) {
  .button-container {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.menu-button {
  font-family: "Nunito";
  font-weight: 600;
  font-size: 0.85em;
  color: var(--main-font-color);
  text-align: center;
  text-decoration: none;
  padding: 1em 2em;
  margin: 0.2em 1em;
  border-radius: 0.5em;
}

@media (max-width: 820px) {
  .menu-button {
    padding: 0.5em 0;
    margin: 0.5em 0;
    width: 50%;
  }
}

.menu-button:hover {
  cursor: pointer;
  background-color: var(--secondary-bg-color);
}

.action-button {
  color: white;
  background-color: #f1035e !important;
  box-shadow: 0px 0px 10px rgba(240, 0, 92, 0.3),
    0px 15px 30px rgba(250, 0, 83, 0.1) !important;
}

.menu-item {
  height: 30px;
  border-radius: 5px;
  background: #fcfcfc;
  border: 1px solid #ffffff;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05),
    0px 15px 30px rgba(0, 0, 0, 0.04);
}

.menu-item:hover {
  border: 1px solid #f1035e;
}

.support {
  color: #ffffff;
  background: #f1035e;
  border: 1px solid #ff0000;
  box-shadow: 0px 0px 10px rgba(240, 0, 92, 0.3),
    0px 15px 30px rgba(250, 0, 83, 0.1);
}
</style>
