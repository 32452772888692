
<template>
  <div class="spacer" />
</template>

<script>
export default {
  name: "Spacer",
};
</script>

<style scoped>
.spacer {
  flex-grow: 1000;
}
</style>
