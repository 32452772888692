<template>
  <div>
    <input
      @change="toggleTheme"
      id="checkbox"
      type="checkbox"
      class="switch-checkbox"
    />
    <label for="checkbox" class="switch-label">
      <span v-if="activeThemeEquals('light-theme')">🌙</span>
      <span v-if="activeThemeEquals('dark-theme')">☀️</span>
      <div
        class="switch-toggle"
        :class="{ 'switch-toggle-checked': userTheme === 'dark-theme' }"
      ></div>
    </label>
  </div>
</template>

<script>
export default {
  created() {
    if (localStorage.getItem("user-theme") === null) {
      this.setTheme(this.userTheme);
    } else {
      this.setTheme(localStorage.getItem("user-theme"));
    }
  },
  data() {
    return {
      userTheme: "light-theme",
    };
  },

  methods: {
    toggleTheme() {
      const activeTheme = localStorage.getItem("user-theme");
      if (activeTheme === "light-theme") {
        this.setTheme("dark-theme");
      } else {
        this.setTheme("light-theme");
      }
    },

    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },
    activeThemeEquals(theme) {
      const active_theme = localStorage.getItem("user-theme");
      return active_theme === theme;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.switch-checkbox {
  display: none;
}

.switch-label {
  display: flex;
  justify-content: center;
  font-size: 1.5em;
  text-align: center;
  border-radius: 0.4em;
  cursor: pointer;
  position: relative;
  width: 1.5em;
  padding: 20% 20%;
}

.switch-label:hover {
  background: var(--main-font-color);
}
</style>
