
<template>
  <div class="container">
    <a class="menu-button" href="/">
      <img src="../assets/logo/logo.svg" class="header-image themed-svg-icon" />
    </a>
    <Menu />
    <ThemeButton />
  </div>
</template>

<script>
import Menu from "../components/Menu.vue";
import ThemeButton from "../components/ThemeButton.vue";

export default {
  name: "Header",
  components: {
    Menu,
    ThemeButton,
  },
};
</script>

<style scoped>
.container {
  background-color: var(--main-bg-color);
  border-radius: 10px;
  box-shadow: var(--first-shadow), var(--second-shadow);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  gap: 10px;
  border-bottom: 2px solid var(--secondary-bg-color);
}

@media (max-width: 500px) {
  .container {
    flex-direction: column;
  }
}

.header-image {
  position: relative;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 28px;
}

.logo {
  display: grid;
  min-width: 10em;
}

.logo-text {
  position: relative;
  padding-left: 2em;
}

.logo-block {
  position: absolute;
  width: 20px;
  height: 20px;
  background: var(--main-font-color);
  border-radius: 3px;
}
</style>
