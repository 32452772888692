<template>
  <div class="main-div">
    <Header />
    <!-- <Warning /> -->
    <router-view />
    <Spacer />
    <Footer />
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
import Spacer from "./components/Spacer.vue";
// import Warning from "./components/Warning.vue";

export default {
  name: "App",
  components: {
    Footer,
    Header,
    Spacer,
    // Warning
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Inter");
@import url("https://fonts.googleapis.com/css2?family=Nunito");

pre {
  padding-top: 20px;
  padding-bottom: 20px;
}

code {
  padding: 30px !important;
  border-radius: 10px;
  font-size: 14px;
}

:root {
  --main-bg-color: #fcfcfc;
  --main-accent-color: #f1035e;
  --main-font-color: #101010;

  --secondary-bg-color: #e6e6e6;
  --lighter-font-color: #c4c4c4;

  --first-shadow: 0px 10px 10px rgba(0, 0, 0, 0.02);
  --second-shadow: 0px 20px 25px rgba(0, 0, 0, 0.04);

  --icon-filter: ;
  --brightness-filter: brightness(115%);

  --background-color-primary: #ebebeb;
  --background-color-secondary: #fafafa;
  --accent-color: #cacaca;
  --text-primary-color: #222;
  --element-size: 4rem;
}

:root.dark-theme {
  --main-bg-color: #272727;
  --main-accent-color: #f1035e;
  --main-font-color: #cccccc;

  --secondary-bg-color: #535353;
  --lighter-font-color: #5c5c5c;

  --first-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  --second-shadow: 0px 20px 25px rgba(0, 0, 0, 0.2);

  --icon-filter: invert(90%);
  --brightness-filter: invert(100%);

  --background-color-primary: #1e1e1e;
  --background-color-secondary: #2d2d30;
  --accent-color: #3f3f3f;
  --text-primary-color: #ddd;
}

.themed-svg-icon {
  filter: var(--icon-filter);
}

.main-div {
  background-color: var(--main-bg-color);
  position: relative;
  width: 100%;
  max-width: 1000px;
  min-height: 97vh;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 70px;
}

.container-submenu {
  margin-top: -60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dead-center {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
}

.invisible-link {
  color: #cccccc;
  display: inline;
  text-decoration: none;
}

.invisible-link:visited {
  color: #cccccc;
}

html {
  background: var(--main-bg-color);
  min-height: 100vh;
}

#app {
  min-height: 100vh;
}

body {
  margin: 0;
  min-height: 100vh;
}

h1 {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: var(--main-font-color);
}

h2 {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: var(--main-font-color);
}

h3 {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: var(--main-font-color);
}

h4 {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: var(--main-font-color);
}

.h5 {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--main-font-color);
}

.h6 {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--main-font-color);
}

p {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: var(--main-font-color);
}

p a {
  color: var(--main-accent-color);
}

li {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: var(--main-font-color);
}

li a {
  color: var(--main-accent-color);
}

table {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: var(--main-font-color);
  width: 100%;
  border-radius: 10px;
  border: none;
  border-collapse: collapse;
  box-shadow: var(--first-shadow), var(--second-shadow);
}

th,
td {
  text-align: left;
}

th {
  color: white;
  background-color: var(--main-accent-color);
  padding: 1.4em 2em;
}

td {
  padding: 1em 2em;
}

table th:first-child {
  border-radius: 10px 0 0 0;
}

table th:last-child {
  border-radius: 0 10px 0 0;
}

tr:nth-child(even) td {
  background-color: var(--secondary-bg-color);
}

tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}

tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}

.body-1 {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--main-font-color);
}

.body-2 {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  color: var(--main-font-color);
}

.caption-1 {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: var(--main-font-color);
}

.caption-2 {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--main-font-color);
}

.footnote-1 {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: var(--main-font-color);
}

.footnote-2 {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: var(--main-font-color);
}
</style>
